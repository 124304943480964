<template>
  <div class="container">
    <b-col
      lg="6"
      sm="12"
      xs="12"
      md="8"
      offset-lg="3"
      offset-md="2"
    >
      <b-card
        class="hubu-bg-red"
        :header="$t('forgot')"
        header-tag="header"
      >
        <b-form @submit="submitForm">
          <div v-if="errors.invalid">
            {{ errors.invalid }}
          </div>
          <b-form-group
            id="input-group-1"
            :label="$t('auth.email_label')"
            label-for="input-1"
            :description="$t('auth.email_tip')"
          >
            <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              required
              :placeholder="$t('auth.email_placeholder')"
            ></b-form-input>
          </b-form-group>

          <div>
            <b-button
              class="hubu-btn-red mr-2"
              size="md"
              type="submit"
              variant="primary"
            >
              {{ $t("submit") }}
            </b-button>
          </div>
        </b-form>
      </b-card>
    </b-col>
    <b-modal
      id="confirm"
      :title="$t('forgot')"
      header-bg-variant="red"
      header-text-variant="white"
      body-bg-variant="white"
      body-text-variant="dark"
      footer-bg-variant="white"
      footer-text-variant="red"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            {{ $t('check_email') }}
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="outline-red"
            size="lg"
            class="float-right"
            @click="$bvModal.hide('confirm')"
          >
            {{ $t('ok') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
//import Cookies from 'js-cookie'
export default {
  name: "Login",
  data () {
    return {
      form: {
        email: null,
      },
      errors: {
        invalid: null,
      },
    };
  },
  created () {
    if (this.$store.getters.loggedIn) {
      this.$router.push({
        name: "Home",
      });
    }
  },
  methods: {
    submitForm (e) {
      e.preventDefault();

      this.$store.dispatch("forgotPassword", { email: this.form.email }).then(
        () => {
          this.$bvModal.show("confirm");
        },
        (err) => {
          this.errors.invalid = this.$t(
            err.response.data.message[0].messages[0].id,
          );
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu-bg-red {
  border: 1px solid $red;
}

.hubu-btn-red {
  background-color: $red;
  color: $white;
  font-weight: bold;
  border: 0px;
}

.btn-white {
  background-color: $white;
  color: $red;
  font-weight: bold;
  border: 1px solid $red;
}

header {
  background-color: $red;
  border-color: $red;
  font-size: 24px;
  color: $orange;
  font-weight: bold;
}
</style>
